import { Box, Label, Input, Textarea, Button, Message, Spinner } from 'theme-ui';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
export default {
  Box,
  Label,
  Input,
  Textarea,
  Button,
  Message,
  Spinner,
  Trans,
  useTranslation,
  React
};